<template>
  <div class="about">
    <img v-for="(item,index) in pageAd" :key="index" :src="serverUrl + item.savePath" alt="">
    <div class="tabList">
      <div class="tabs-wrap">
        <div class="tabs">
          <div class="item" :class="tabIndex === index ? 'active' : ''" v-for="(item,index) in tabs" :key="index"
               @click="selectTabItem(index)">{{ item }}
          </div>
        </div>
      </div>
      <div class="tabs-content">
        <div class="first-tab" v-if="tabIndex === 0 && contactInfo">
          <div class="first-tab">
            <p class="title">{{ contactInfo.title }}</p>
          </div>
          <div class="call-content" v-html="contactInfo.content">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getAd, getArtfir} from "@/api/api";
import {serverUrl} from "@/utils/config";

export default {

  data() {
    return {
      serverUrl: serverUrl,
      activeName: "first",
      tabs: ["联系我们"],
      tabIndex: 0,
      contactInfo: null,
      pageAd: []
    };
  },
  mounted() {
    this.getData();
    this.getPageAd();
  },
  methods: {
    getPageAd(){
      getAd({
        code: 'contact_ad'
      }).then((resp) => {
        let {code, data} = resp;
        if (code === 0) {
          this.pageAd = data;
        }
      })
    },
    getData() {
      getArtfir({
        categoryId: 11
      }).then((resp) => {
        let {code, data} = resp;
        if (code === 0) {
          this.contactInfo = data;
        }
      })
    },
    selectTabItem(tabIndex) {
      this.tabIndex = tabIndex;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
